body {
    background-color: rgb(41, 11, 11);
    margin: 0;
}

.sectionContent p {
    margin-top: 0px;
}

/* BOB.css adjustments */
#sectionHtml {
    margin: inherit;
}

.sectionContentTitle {
    margin-top: 65px;
    text-align: left;
    font-size: 2rem !important;
    font-family: Helvetica, sans-serif;
    font-weight: 700;
}

@media (max-width: 768px) {
    .sectionContentTitle {
        margin-top: 30px;
       text-align: center;
    }
}

.sectionContent {
    max-width: 650px;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
}

@media (max-width: 768px) {
    .sectionContent {
        position: inherit;
        top: auto;
        display: inherit;
        padding-bottom: 50px;
        width: 100% 
    }
}

.bob-audio-outer-div {
    left: 0;
    max-width: 50%;
}


@media (max-width: 768px) {
    .bob-audio-outer-div {
        left: auto;
        max-width: inherit;
    }
}

/*
*
    LOGIN
*
*/

.login-container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 978px;

}

@media (max-width: 768px) {
    .login-container {
        flex-direction: column;
    }
}

/* LEFT SIDE */

.login-bg {
    flex: 1;

    height: 100vh;
    background: url("https://thelastchameleon-assets.s3.us-west-2.amazonaws.com/images/cover.jpeg");
    background-size: calc(100% - 30px);
    background-repeat: no-repeat;
    background-position: center;
    padding: 15px; 
    max-height: 880px;
}

@media (max-width: 768px) {
    .login-bg {
        flex-basis: auto;
        height: calc(100vh - 105px);
        background-size: 100%;
        background-position: center bottom;
        padding: 0px; 
    }
}

.login-text {
    padding: 5px;
}

@media (max-width: 768px) {
    .login-text {
        background: rgba(41, 11, 11, .7);
        padding-bottom: 15px;
    }
}

.login-title,
.login-tagline {
    text-align: center;
    color: white;
}

.login-title {
    font-family: fantasy, Helvetica, sans-serif;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 5px;
}

@media (max-width: 768px) {
    .login-title {
        font-size: 1.8rem;
        margin: 0;
    }
}

.login-tagline {
    margin-top: 5px;
}

@media (max-width: 768px) {
    .login-tagline {
        margin: 0;
    }
}


/* RIGHT SIDE */

.login-info {
    display: flex;
    align-items: center;
    flex: 1;
}

.login-form {
    background: rgb(213,213,213);
    border: 3px solid black;
    max-width: calc(100% - 26px);
    padding: 15px;
    margin: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .login-form {
        border: 0;
        border-radius: 0;
        max-width: calc(100% - 20px);
        padding: 10px;
        margin: 0px;
        flex-direction: column-reverse;
    }
}


.login-form .login-form-title {
    margin-bottom: 5px;
}

.login-form .login-form-tagline {
    margin-top: 5px;
}

.login-form input {
    margin-top: 15px;
    font-size: 2rem;
    max-width: calc(100% - 30px);
}

@media (max-width: 768px) {
    .login-form input {
        margin-top: 0px;
    }
}

.login-form button {
    display: block;
    font-size: 1.5rem;
    margin-top: 15px;
}

.login-email-exists {
    color: green;
}

.login-email-exists.warning {
    color: rgb(138, 93, 2);
}



/*
*
    SCENES
*
*/



.scene-container {
    display: flex;
    justify-content: center;
    max-width: 978px;
    margin: 0 auto;

}

@media (max-width: 768px) {
    .scene-container {
        flex-direction: column;
    }
}

/* LEFT SIDE */

.scene-bg {
    display: flex;
    flex-direction: column;
    flex: 1;

    height: 100vh;
    background: rgb(41, 11, 11);
    padding: 10px;
    height: calc(100vh - 90px);

    /* DESKTOP ONLY */
    width: calc(50% - 20px);
    max-width: 768px;
    left: 0;
     position: fixed;
}

@media (max-width: 768px) {
    .scene-bg {
        flex-basis: auto;
        padding: 0px;
        max-width: inherit;
        left: auto;
        position: inherit;
        margin-left: inherit;
        width: 100%;
    }
}

@media (min-width: 1536px) {
    .scene-bg {
        margin-left: calc(25% - 384px);
    }
}
.scene-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: rgb(41, 11, 11);
}

.scene-menu {
    padding: 6px;
    margin-top: 9px;
}

.scene-icon {
    max-width: 25px;
    cursor: pointer;
}

.scene-number {
    display: flex;
    justify-content: flex-end;
    padding: 6px;
    width: 42px;
}

.scene-number span {
    font-family: initial;
    font-weight: bold;
    color: white;
    font-size: 2.2rem;
    text-align: right;
}

.scene-cover {
 
    background-size: contain; 
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.scene-cover.scene-part {
    align-items: stretch;
}

.scene-cover img {
    max-width: 100%;
}

.scene-text {
    padding: 5px;
}

@media (max-width: 768px) {
    .scene-text {
        background: rgba(41, 11, 11, .7);
    }
}

.scene-title,
.scene-tagline {
    color: white;
    text-align: center;
}

.scene-title {
    font-size: 1.7rem;
    line-height: 1.5rem;
    padding: 5px 0px;
    margin-top: 0;
    color: #d9bb83;
}

@media (max-width: 768px) {
    .scene-title {
        font-size: 1.4rem;
        margin: 0;
    }
}

.scene-tagline {
    margin-top: 5px;
}

@media (max-width: 768px) {
    .scene-tagline {
        margin: 0;
    }
}

.scene-quote {

    padding: 10px 15px 20px 15px;
    margin: 0 auto 10px auto;
    font-size: 1.2rem;

    max-width: 500px;

    color: white;
    font-style: italic;
}

@media (max-width: 768px) {
    .scene-quote {
        margin: 8px auto 0px auto;

        font-size: 1rem;
        background: linear-gradient(180deg, rgba(41, 11, 11, .4) 0%, rgba(41, 11, 11, .7) 15%, rgba(41, 11, 11, .5) 76%, rgba(221, 199, 169, 1) 100%)

    }
}

.scene-quote b {
    color: #d9bb83
}

#quoteContainer {
    background-color: inherit;
    height: auto;
}

@media (max-width: 768px) {
    #quoteContainer {
        background-color: inherit;
        height: auto;
        max-height: 56px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }

}


/* RIGHT SIDE */

.scene-info {
    display: flex;
    align-items: center;
    flex: 1;

    background: rgb(231 224 215);
    padding: 10px;
    overflow: auto;

    /* DESKTOP ONLY */
    width: calc(50% - 20px);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}

@media (max-width: 768px) {
    .scene-info {
        width: auto;
        max-width: inherit;
        left: auto;
        position: inherit;
        
        overflow: inherit;
    }
}

.scene-info p {
    color: rgb(41, 11, 11);
    font-size: 1.1em;
}


/*
*
     TABLE OF CONTENTS
*
*/

#menuScreen {
    border: 0;
}

#menuScreen .scene-header .scene-text{
    display: flex;
    align-items: center;
}

.menuScreenTabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    cursor:pointer;
}
.menuScreenTab {
    margin: 0px 3px;    
    flex: 0 1 20%;
    width: 75px;
    border-top: 0;
}
.menuScreenTab.tabSelected {
    background: #d9bb83;
    border: 1px solid #d9bb83;
    border-top: 0;
}

#indexTab {
    margin-top: 20px;
    overflow: hidden;
}


@media (max-width: 768px) {
    #indexTab {
        
    }
}


.partHeader {
    flex-basis: 100%;
    max-width: 100%;
    margin-top: 10px;
}

.pictureIndexPartHeader {
    display: flex;
    justify-content: center;
    padding: 5px 20px 5px;
    background-color: white;
}

.pictureIndexPartHeader h3 {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 5px;
    margin-bottom: -30px;
    z-index: 10;
    background: white;
    padding: 10px;
    border: 1px solid black;
    border-bottom: 0;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}


@media (max-width: 768px) {
    .pictureIndexPartHeader h3 {
        margin-bottom: inherit;
        z-index: 10;
        background: white;
        padding: inherit;
        border: 0;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
                
    }
}

.pictureIndexPartBackground {
    max-height: 45px;
    max-width: 1200px;
}

@media(max-width: 768px) {
        
    .pictureIndexPartBackground {
        max-height: 15px;
    }
}

.pictureIndexDiv {
    z-index: 100;
    padding: 0;
    border: 0;
    width: 170px;
    height: 270px;
    border: 2px solid white;
    cursor:pointer;
    overflow:hidden;
}

.pictureIndexDivHeader {
    flex-basis: auto;
    font-size: .9rem;
    padding: 5px;
}

.pictureIndexDivImage {
    height: auto;
    width: auto;
    object-fit: contain;
    border-radius: 0;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}


.tocPart {
    margin-top: 35px;
}

.tocSection {
    color:rgb(138, 93, 2);
    margin-left: 35px;
    margin-top: 20px;
    margin-bottom: 20px;
}



.corey-arrow-right {
    width: 0; 
    height: 0; 
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    
    border-left: 20px solid rgb(138, 93, 2);

    display: inline-block;
    position: relative;
    left: 60px;
    bottom: 12px;
  }



.hide {
    display: none;
}