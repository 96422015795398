/*
  1. Base
*/
/*
  2. Components
*/
div#list-screen {
  background-color: #141920;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  display: none;
}

div#list-screen div#list-screen-header {
  background-color: #141920;
  padding: 15px;
  text-align: center;
  margin-top: 30px;
  position: relative;
  color: white;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}

div#list-screen div#list-screen-header img#up-arrow {
  position: absolute;
  top: 23px;
  left: 15px;
  cursor: pointer;
}

div#list-screen div#list-screen-header span.list-active-song-name {
  color: #fff;
  line-height: 25px;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  letter-spacing: 0.5px;
}

div#list-screen div#list {
  overflow-y: scroll;
}

div#list-screen div#list div.song {
  padding: 15px;
  background-color: #242b33;
  margin-bottom: 1px;
  cursor: pointer;
  line-height: 24px;
}

div#list-screen div#list div.song span.song-number-now-playing {
  display: inline-block;
  width: 24px;
  text-align: center;
}

div#list-screen div#list div.song span.song-number-now-playing span.number {
  opacity: 0.5;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.5px;
}

div#list-screen div#list div.song span.song-number-now-playing img.now-playing {
  display: none;
}

div#list-screen div#list div.song.amplitude-active-song-container {
  background-color: #30363e;
}

div#list-screen div#list div.song.amplitude-active-song-container span.song-number-now-playing img.now-playing {
  display: inline-block;
}

div#list-screen div#list div.song.amplitude-active-song-container span.song-number-now-playing span.number {
  display: none;
}

div#list-screen div#list div.song div.song-meta-container {
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle;
  width: calc(100% - 80px);
}

div#list-screen div#list div.song div.song-meta-container span.song-name {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #55b560;
  letter-spacing: 0.47px;
  display: block;
}

div#list-screen div#list div.song div.song-meta-container span.song-artist-album {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  letter-spacing: 0.47px;
  display: block;
}

div#list-screen div#list div.song span.song-duration {
  display: inline-block;
  opacity: 0.5;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.5px;
  width: 35px;
  text-align: center;
}

div#list-screen div#list div.song span.song-artist-album {
  color: #5277b0;
}

div#list-screen div#list-screen-footer {
  height: 80px;
  padding: 15px;
}

div#list-screen div#list-screen-footer div#list-screen-meta-container {
  width: calc(100% - 123px);
  float: left;
}

div#list-screen div#list-screen-footer div#list-screen-meta-container span.song-name {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.5px;
  line-height: 24px;
}

div#list-screen div#list-screen-footer div#list-screen-meta-container div.song-artist-album {
  opacity: 0.5;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.5px;
  line-height: 16px;
}

div#list-screen div#list-screen-footer div.list-controls {
  width: 90px;
  float: right;
  margin-top: 8px;
}

div#list-screen div#list-screen-footer div.list-controls div.list-previous {
  cursor: pointer;
  width: 15px;
  height: 17px;
  background-image: url("https://521dimensions.com/img/open-source/amplitudejs/examples/flat-black/small-previous.svg");
  background-repeat: no-repeat;
  float: left;
  margin-right: 15px;
  margin-top: 4px;
}

div#list-screen div#list-screen-footer div.list-controls div.list-play-pause {
  cursor: pointer;
  width: 17px;
  height: 24px;
  background-repeat: no-repeat;
  float: left;
}

div#list-screen div#list-screen-footer div.list-controls div.list-play-pause.amplitude-playing {
  background-image: url("https://521dimensions.com/img/open-source/amplitudejs/examples/flat-black/small-pause.svg");
}

div#list-screen div#list-screen-footer div.list-controls div.list-play-pause.amplitude-paused {
  background-image: url("https://521dimensions.com/img/open-source/amplitudejs/examples/flat-black/small-play.svg");
}

div#list-screen div#list-screen-footer div.list-controls div.list-next {
  cursor: pointer;
  width: 15px;
  height: 17px;
  background-image: url("https://521dimensions.com/img/open-source/amplitudejs/examples/flat-black/small-next.svg");
  background-repeat: no-repeat;
  float: left;
  margin-left: 15px;
  margin-top: 4px;
}

div.player-header {
  background-color: #141920;
  padding: 15px;
  text-align: center;
  height: 25px;
  position: relative;
  margin-top: 40px;
  cursor: pointer;
  color: white;
  font-family: "Lato", sans-serif;
}

div.player-header img#down {
  position: absolute;
  top: 23px;
  left: 15px;
  cursor: pointer;
}

div#player-top {
  position: relative;
}

div#player-top img[data-amplitude-song-info="cover_art_url"] {
  width: 100%;
}

div#player-progress-bar-container {
  width: 100%;
  height: 20px;
  background-color: #000;
  position: relative;
  border: 1px solid black;
  margin-top: -6px;
}

div#player-progress-bar-container progress#song-played-progress {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 20px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9;
  border: none;
  cursor: pointer;
  background: transparent;
}

div#player-progress-bar-container progress#song-played-progress[value]::-webkit-progress-bar {
  background: none;
}

div#player-progress-bar-container progress#song-played-progress[value]::-webkit-progress-value {
  background: white;
}

div#player-progress-bar-container progress#song-played-progress[value]::-moz-progress-bar {
  background: white;
}

div#player-progress-bar-container progress#song-buffered-progress {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 20px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  border: none;
  background: transparent;
}

div#player-progress-bar-container progress#song-buffered-progress[value]::-webkit-progress-bar {
  background: none;
}

div#player-progress-bar-container progress#song-buffered-progress[value]::-webkit-progress-value {
  background-color: rgba(255, 255, 255, 0.5);
  transition: width 0.1s ease;
}

div#player-progress-bar-container progress#song-buffered-progress[value]::-moz-progress-bar {
  background: rgba(255, 255, 255, 0.5);
}

div#player-middle {
  background-color: #000;
  padding-top: 5px;
  text-align: center;
  position: relative;
}

div#player-middle div#time-container {
  color: white;
  width: 100%;
  background-color: #000;
  font-size: 14px;
  font-weight: bold;
}

div#player-middle div#time-container span.amplitude-time-remaining {
  position: absolute;
  top: 0px;
  right: 5px;
}

div#player-middle div#time-container span.amplitude-current-time {
  position: absolute;
  top: 0px;
  left: 5px;
}

div#player-middle span.song-name {
  font-family: Lato, sans-serif;
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.5px;
  line-height: 24px;
  display: block;
}

div#player-middle div.song-artist-album {
  opacity: 0.5;
  font-family: Lato, sans-serif;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.5px;
  line-height: 16px;
}

div#player-bottom {
  background-color: #192029;
  /* padding-left: 25px;
    padding-right: 25px;*/
}

div#player-bottom div#control-container {
  height: 90PX;
  margin-right: 10px;
  padding-top: 5px;
  margin-left: 10px;
  display: flex;
}

/* back15 */
div#player-bottom div#control-container div#back15-container {
  flex-basis: 20%;
  width: 20%;
  height: 60px;
  padding-top: 15px;
}

div#player-bottom div#control-container div#back15-container div#back15 {
  width: 40px;
  border-radius: 10px;
  cursor: pointer;
  opacity: 0.7;
  border: 2px dashed white;
  color: white
}

/* forward15 */

div#player-bottom div#control-container div#forward15-container {
  flex-basis: 20%;
  width: 20%;
  height: 60px;
  padding-top: 15px;
}

div#player-bottom div#control-container div#forward15-container div#forward15 {
  width: 40px;
  float: right;
  border-radius: 10px;
  cursor: pointer;
  opacity: 0.7;
  border: 2px dashed white;
  color: white
}

/*speed*/
.amplitude-playback-speed-10:after {
  content: "1X"
}

.amplitude-playback-speed-15:after {
  content: "1.5X"
}

.amplitude-playback-speed-20:after {
  content: "2.0X"
}

.speedButton {
  width: 40px;
  border-radius: 10px;
  cursor: pointer;
  opacity: 0.7;
  border: 2px dashed white;
  color: white
}

/*prevous*/
div#player-bottom div#control-container div#prev-container {
  flex-basis: 20%;
  height: 60px;
  padding-top: 18px;
}

div#player-bottom div#control-container div#prev-container div#previous {
  background: url("https://521dimensions.com/img/open-source/amplitudejs/examples/flat-black/previous-hover.svg");
  width: 24px;
  height: 24px;
  cursor: pointer;
}

/*play*/
div#player-bottom div#control-container div#play-pause-container {
  flex-basis: 20%;
  height: 60px;
}

div#player-bottom div#control-container div#play-pause-container div#play-pause {
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin: auto;
}

div#player-bottom div#control-container div#play-pause-container div#play-pause.amplitude-playing {
  background: url("https://521dimensions.com/img/open-source/amplitudejs/examples/flat-black/pause.svg");
}

div#player-bottom div#control-container div#play-pause-container div#play-pause.amplitude-paused {
  background: url("https://521dimensions.com/img/open-source/amplitudejs/examples/flat-black/play.svg");
}

/*next*/
div#player-bottom div#control-container div#next-container {
  flex-basis: 20%;
  height: 60px;
  padding-top: 18px;
}

div#player-bottom div#control-container div#next-container div#next {
  background: url("https://521dimensions.com/img/open-source/amplitudejs/examples/flat-black/next-hover.svg");
  width: 24px;
  height: 24px;
  cursor: pointer;
  float: right;
}

div#player-bottom div#control-container::after {
  content: "";
  display: table;
  clear: both;
}

div#player-bottom div#volume-container {
  padding-bottom: 20px;
  text-align: center;
}

div#player-bottom div#volume-container img {
  display: block;
  float: left;
  margin-top: -9px;
}

div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider {
  width: calc(100% - 30px);
  -webkit-appearance: none;
  display: block;
  margin-left: 10px;
  float: left;
}

div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider:focus {
  outline: none;
}

div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider::-webkit-slider-runnable-track {
  width: 75%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: #cfd8dc;
}

div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider::-webkit-slider-thumb {
  height: 18px;
  width: 18px;
  background-color: white;
  cursor: pointer;
  margin-top: -8px;
  -webkit-appearance: none;
  border-radius: 20px;
}

div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider:focus::-webkit-slider-runnable-track {
  background: #cfd8dc;
}

div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  animate: 0.2s;
  background: #cfd8dc;
}

div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider::-moz-range-thumb {
  height: 18px;
  width: 18px;
  background-color: white;
  cursor: pointer;
  margin-top: -8px;
  -webkit-appearance: none;
  border-radius: 20px;
}

div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: #cfd8dc;
  border-width: 15px 0;
  color: transparent;
  border-color: #192029;
}

div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider::-ms-fill-lower {
  background: transparent;
  border-radius: 2.6px;
}

div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider::-ms-fill-upper {
  background: transparent;
  border-radius: 2.6px;
}

div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider::-ms-thumb {
  height: 18px;
  width: 18px;
  background-color: white;
  cursor: pointer;
  margin-top: 0px;
  border: 20px;
}

div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider:focus::-ms-fill-lower {
  background: #cfd8dc;
}

div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider::-ms-fill-upper {
  background: #cfd8dc;
}

div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider::-ms-tooltip {
  display: none;
}

div#player-bottom div#volume-container:after {
  content: "";
  display: table;
  clear: both;
}

@-moz-document url-prefix() {
  div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider {
    margin-top: 0px;
  }
}

@supports (-ms-ime-align: auto) {
  div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider {
    margin-top: -4px;
    height: 30px;
    background-color: #192029 !important;
  }
}

@media all and (-ms-high-contrast: none) {

  div#player-bottom div#volume-container *::-ms-backdrop,
  div#flat-black-player-container div#player-bottom div#volume-container input[type="range"].amplitude-volume-slider {
    margin-top: -8px;
    background-color: #192029 !important;
    background: #192029 !important;
  }
}

/*
    3. Layout
  */

div#flat-black-player-container {
  position: relative;
  max-width: 450px;
}

/*
    4. Pages
  */
/*
    5. Themes
  */
/*
    6. Utils
  */
/*
    7. Vendors
  */
/*
    8. Animations
  */
.slide-in-top {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
   * Generated by Animista on 2019-3-25 18:39:54
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
/**
   * ----------------------------------------
   * animation slide-in-top
   * ----------------------------------------
   */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-out-top {
  -webkit-animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* ----------------------------------------------
   * Generated by Animista on 2019-3-25 18:45:17
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
/**
   * ----------------------------------------
   * animation slide-out-top
   * ----------------------------------------
   */
@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }
}

@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }
}

/*# sourceMappingURL=app.css.map */

a.learn-more {
  background-color: #141920;
  display: block;
  width: 300px;
  color: white;
  text-align: center;
  margin: auto;
  margin-top: 20px;
  text-decoration: none;
  padding: 10px;
}