/* Layout */
#loading {
    opacity: 0.8;
    background-color: #ccc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 10000;
}

#audioControl {
    position: fixed;
    bottom: 0;
    width: 95%;
    margin-left: 5px;
    border-radius: 10px;
    border: 1px solid black;
}

#songScreen {
    background-color: black;
}

#songScreenInner {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

#playerScreen {
    height: 100vh;
}

#playerHeaderOuter {
    height: 10vh;
    background-color: black;
}

#playerHeaderInner {
    margin: auto;
    margin-top: 7px;
}
/* 
#sectionImageContainer {
    height: 74vh;
    background-color: black;
} */

#quoteContainer {
    height: 16vh;
    background-color: black
}

/*#sectionHtml{
    margin:auto;
    color:white;
    padding:8px;
}*/
#sectionHtml {
    margin: inherit;
}

#section {
    position: relative;
    min-height: 100vh;
}

#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    /* Footer height */
}

#menuScreen {
    z-index: 1000;
    background-color: white;
    border: 2px solid black;
}

#tocTab {
    z-index: 1000;
    background-color: white;
    padding-left: 5px;
}

.indexTabInner {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    justify-content: space-evenly;
}

.sectionImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
}

.closeReadAlong {
    background: white;
    color: black;
    padding: 5px;
    border-radius: 10px;
    font-size: 1.5rem;
    height: 25px;
    flex-basis: 30px;
    margin-left: -6px;
}

.smallPlaybar {
    height: 5vh;
    display: flex;
    flex-wrap: noWrap;
    position: sticky;
    bottom: 0;
    opacity: 1;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
    background-color: black;
    border-top: 1px solid white;
    padding-top: 5px;
}

.smallPlayButton {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    float: left;
}

.smallPlayButton.amplitude-playing {
    background-image: url("https://521dimensions.com/img/open-source/amplitudejs/examples/flat-black/small-pause.svg");
}

.smallPlayButton.amplitude-paused {
    background-image: url("https://521dimensions.com/img/open-source/amplitudejs/examples/flat-black/small-play.svg");
}

#readAlongSentences {
    overflow: auto;
    flex-grow: 1;
    padding-right: 3px;
    margin-right: 13px;
}

.readAlongCurrent {
    background-color: #313639;
    color: white;
    font-size: 1.2rem;
    padding: 3px;
    text-align: center;
    border-radius: 15px;

}

.readAlongOneAway {
    background-color: black;
    color: lightgray;
    font-size: .9rem;
    margin-right: 5px;
    text-align: center;
    margin-left: 28px;
    margin-right: 28px;
}

.readAlongDefault {
    background-color: black;
    color: lightgray;
    font-size: .7rem;
    margin-right: 10px;
    text-align: center;
    margin-left: 28px;
    margin-right: 28px;
}

.readAlongOuter {
    height: 40vh;
    width: 100%;
    max-width: 503px;
    background-color: black;
    z-index: 2000;
    position: fixed;
    bottom: 0px;
    border: 1px solid black;
    padding: 5px;
    border-top: 1px solid white
}

.readAlongInner {
    height: 35vh;
    width: 100%;
    padding: 5px;
    display: flex;
    color: white;
}

.tabSelected {
    background-color: orange;
}

/*.sectionContentTitle{
   text-align: center;
   font-size: 1.5em; 
}*/
.sectionContentTitle {
    text-align: left;
}

.sectionContent {
    max-width: 550px;
    color: wheat;
    padding: 5px;
}

.menuScreenTab {
    flex-grow: 1;
    text-align: center;
    font-size: 1.1rem;
    border: 1px solid black;
    border-radius: 0px 0px 15px 15px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 5px;
}

.menuBar {
    margin: auto;
    text-align: center;
    height: 35px;
}

.menuButton {
    color: black;

    padding: 5px 10px 10px 10px;
    width: 60px;
    border: 1px solid black;
    text-align: center;
    font-size: 1rem;
    border-radius: 20px 20px 0px 0px;
    opacity: .80;
    background: white;
}

.loginTitle {
    color: white;
    margin-top: 30px;
    text-align: center;
    font-size: 2rem;
}

.topContainer {
    max-width: 1050px;
    height: 80%;
    margin: auto;
}

.navIcon {
    height: 25px
}

.sectionContainer {
    display: flex;
    flex-wrap: wrap;
}

.nonSectionContainer {
    padding: 10px;
    background-color: white;
    display: none;
}

.nonSectionContainer h1 {
    margin-top: 0px;
    color: black;
    font-size: 1.5rem;
}


.nonSectionContainer h2 {
    margin-top: 0px;
    color: black;
    font-size: 1rem;
}




.tocTitle {
    font-size: 1.5rem;
    margin-top: 0px;
    margin-bottom: 10px;
    ;
}

.tocSubTitle {
    font-size: 1rem;
    margin-top: 0px;
    margin-bottom: 10px;
    ;
}

.tocPart {
    font-size: 1.5rem;
    cursor: pointer;
    margin-bottom: 3px;
}

.tocSection {
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: 9px;
    cursor: pointer;
    font-size: 1.25em;
}

.fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
}

.show {
    display: block;
}

.showInline {
    display: inline;
}

.hide {
    display: none;
}

.resumeOpen {
    display: flex
}

.resumeClosed {
    display: none
}

.resumeButton {
    background-color: chocolate;
    color: white;
    flex-basis: 50%;
    height: 30px;


}

.sectionLink {
    color: black;
    cursor: pointer;
    z-index: 2;
    position: relative;
    margin-top: 0px;
    font-size: 1.5rem;
}

.partDivOuter {
    background-color: white;
    display: flex;
    flex: 1 0 100%;
    justify-content: center;
}

.partDivInner {
    position: relative;
    top: 0;
    padding-bottom: 20px;
    padding-top: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.partDivInner h2 {
    font-size: 1.7rem;
    margin-top: 5px;
    margin-bottom: 0px;
    padding-bottom: 5px;
    border-bottom: 1px solid #313639;
    font-weight: 500;
    font-family: Helvetica, sans-serif;
}

.partDivInner > b {
    font-size: 1.4rem;
    font-weight: 700;

}

.partDivBackgroundImage {
    z-index: 1;
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    opacity: .2;
    object-fit: cover
}

.bob-section-title {
    text-align: center;
    font-size: 1.25rem;
    color: white;
    margin: 0
}

.bob-section-in-which {
    text-align: center;
    font-size: 1rem;
    color: white;
    margin: 0;
    margin-top: 3px;
}


.bob-audio-outer-div{
     width: 100%;
     position: fixed;
     bottom: 0; 
     z-index: 1000;
    height: 92px;
    max-width: 50%;
}

@media (max-width: 768px) {
    .bob-audio-outer-div {
        max-width: 100%;
    }
}

.bob-quote {
    color: white;
    margin-left: 3px;
}

.bob-quote-speaker {
    color: wheat;
    font-weight: bolder;
}

.bob-quote-container {
    margin: auto;
    padding: 10px;

}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: darkgray white;
}

*/

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: black;
}

*::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 20px;
    border: 3px solid white;
}

.textNextPrevConainer {
    display: flex
}

.textPrevContainer {
    flex-basis: 20%;
    height: 60px;
    padding-top: 18px;
}

.textPrevButton {
    background: url("https://521dimensions.com/img/open-source/amplitudejs/examples/flat-black/previous-hover.svg");
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.menuClosed {
    position: fixed;
    top: -20px;
    left: -20px;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    z-index: 1000;
    background: radial-gradient(closest-side, #f69d3c, #ebf8e1, #f69d3c);
    font-size: 0px;
}

.menuOpen {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 2000;
    border: 2px solid white;
    background-color: black;
    font-size: 1rem;
    color: white;
    padding: 10px;
}

.closeButton {
    flex-basis: 25px;
    height: 25px;
    border: 1px solid white;
    border-radius: 5px;
    font-size: 1.25rem;
    text-align: center;
    padding-bottom: 3px;
    margin-top: -12px;
    margin-right: -12px;
    font-weight: 900;
}

.closeButtonOnWhite {
    flex-basis: 50px;
    margin-left: 10px;
    margin-right: 3px;
    border: 1px solid black;
    border-radius: 0px 0px 15px 15px;
    font-size: 1.25rem;
    text-align: center;

    font-weight: 900;
}

.pictureIndexDiv {
    width: 150px;
    height: 250px;
    display: flex;
    flex-direction: column;
    border: 1px solid #c0c0c0d5;
    border-radius: 12px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    margin: 5px;
    background-color: #f5f5f5ef !important;
    padding: 3px;
}

.pictureIndexDivHeader {
    flex-basis: 40px;
    margin: auto;
    font-size: .75rem;
    text-align: center;
    padding-top: 3px;
}

.pictureIndexDivImage {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 10px;
}

.MuiCard-root {
    border: 1px solid #C0C0C0;
    border-radius: 10px !important;
}

textarea {
    font-size: max(1em, 16px);
    border-radius: 6px 6px 6px 6px;
    border-style: none solid solid none;
    border-width: medium 1px 1px medium;
    box-shadow: 0 1px 2px rgb(0 0 0 / 12%) inset;
}

button {
    box-shadow: inset 0px 39px 0px -24px rgb(230, 131, 61);
    background-color: chocolate;
    border-radius: 4px;
    border: 1px solid #ffffff;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 15px;
    padding: 2px 5px;
    text-shadow: 0px 1px 0px chocolate;
}

a {
    color: chocolate;
}


@media only screen and (max-width: 600px) {
    body {
        background-color: lightblue;
    }

    .resumeButton {
        font-size: 3.75vw;
    }
}


@media only screen and (min-width: 1025px) {
    #playerContainer {
        display: flex;
    }

    /* #sectionHtmlContainer {
        width: 50%;
        margin-left: 10px;
        overflow-y: auto;
    } */

    #player-screen {
        width: 50%;
        margin-right: 10px;

    }

    .bob-audio-outer-div {
        max-width: 1050px;
        margin: auto;
    }

    #commentHeader {
        max-width: 1050px;
        margin: auto;

    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {

    .bob-quote-container {
        padding: 25px 35px !important;
        font-size: larger;
    }
}